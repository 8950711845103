<template>
    <div id="settings">
        <div class="container-fluid">
            <Alert :message="error" type='alert-danger' v-if="error" />
            <Alert :message='success' type='alert-success' v-if="success" />
            <div class="row">
                <div class="col-12">
                    <h2 class="my-3" style="font-size:1.2em">Change Password</h2>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg">
                    <div class="card">
                        <div class="card-body">
                            <form class="" @submit.prevent="resestPassword"> 
                                <div class="form-group mb-5 mt-4">
                                    <input type="password" class="form-control" id="password" placeholder="New Password" v-model="password" required>
                                </div>
                                <div class="form-group mb-5">
                                    <input type="password" class="form-control" id="confirm_password" placeholder="Confirm New Password" v-model="confirm_password" required    > 
                                </div>
                                <button style="font-size:.8em; float:right" type="submit" class="btn btn-danger my-4 btn-lg">
                                    Create New Password
                                    <RoundSpinner v-if="loading" />
                                </button>
                            </form> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Alert from '../components/Alert'
import RoundSpinner from '../components/RoundSpinner.vue'

export default {
    components:{
        Alert:Alert,
        RoundSpinner:RoundSpinner
    },
    data(){
        return {
            password:null,
            confirm_password:null,
            error:null,
            success:null,
            loading:false
        }
    },
    methods:{
        resestPassword() {
            this.loading = true
            this.error = null
            this.success = null

            const data = {
                password:this.password
            }
            if(this.password !== this.confirm_password) {
                this.error = 'The password entered does not match'
                this.loading = false
                return
            }

            // reset password here
            this.$store.dispatch('changePassword',data)
                .then(response => {
                    if(response.status === 200) {
                        this.success = 'Password Changed Successfully.'
                        this.loading = false
                        this.password = null
                        this.confirm_password = null
                    }  
                }).catch(err => {
                    if(err.response.data === undefined) {
                        this.loading = false
                        this.error = 'Network Error!. Try Again'
                    }
                    
                })
        }
    }
}
</script>
<style>

</style>